import { ClientOptions } from '@idonatedev/idonate-sdk';

export type EnvironmentVariables = {
  recaptchaGlobalSiteKey: string;
  recaptchaInvisibleKey: string;
  recaptchaCheckboxSiteKey: string;
  spreedlyKey: string;
  baseUrl: string;
  embedBaseUrl: string;
  apiPrefix: string;
  googlePayMerchantId: string;
  googlePayMerchantIdNew: string;
  keenProjectId: string;
  keenWriteKey: string;
  pendoApiKey: string;
  sentry: {
    dsn: string;
    environment: string;
  };
  sdkConfig: ClientOptions;
  easyPass: boolean;
  siftBeaconKey: string;
  pcScriptBase: string;
  pcScriptId: string;
};

declare global {
  interface Window {
    envVars: EnvironmentVariables;
  }
}

export const resolvedEnvironment: EnvironmentVariables = window.envVars;
