/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { recaptcha, util } from '@idonatedev/idonate-sdk';
import iDonateClient from '@idonatedev/idonate-sdk/dist/idonate-client';
import { RecaptchaElement } from '@idonatedev/idonate-sdk/dist/recaptcha';
import { AxiosError } from 'axios';
import { UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  SubmitDonationDataType,
  submitDonation as submitDonationService
} from 'services/donationService';
import { resolvedEnvironment } from 'services/environment';
import { getGooglePayHandler, sdkClientPromise } from 'services/idonateSdk';
import { EditorEventTypes, ScrollPositions } from 'components/EventHub';
import { IGivingFormSchema } from 'components/GivingForm';
import {
  mapCustomFieldToDonation,
  mapRecurringEndCountToEndDate
} from 'components/GivingForm/GivingFormUtils';
import { tokenizeBankAccountCardConnect } from 'components/PaymentProviders/CardConnectACH';
import { tokenizeBankAccountSpreedly } from 'components/PaymentProviders/SpreedlyACH';
import { useGivingFormData, useQueryParam } from 'hooks';
import {
  AllPaymentOptions,
  BlockTypes,
  Designation,
  Donation,
  IDesignationsBlock,
  IGivingFormConfig,
  ITokenizationFields
} from 'types';
import { IDonateQueryParam } from 'types/QueryParams';
import { buildRedirectUrl, getDonationAmount } from 'utils/donationUtils';
import { EventHub } from '../components/EventHub/EventHub.types';
import { submitSiftTracking } from '../services/siftService';
import { useApplePay } from './useApplePay';
import { useChangeRecaptchaPosition } from './useChangeRecaptchaPosition';
import { useCreditCardToken } from './useCreditCardToken';
import { useDisabledOverlay } from './useDisabledOverlay/useDisabledOverlay';
import useDoubleTheDonation from './useDoubleTheDonation';
import { useRecaptchaCheckbox } from './useRecaptchaCheckbox';

export const useDonationSubmission = ({
  emitFormPageChange,
  givingFormConfig,
  isEditMode,
  methods,
  eventHub,
  sessionId,
  setDonationComplete,
  setDonationObject,
  setDonationResponse
}: {
  emitFormPageChange: (scrollPosition: ScrollPositions) => void;
  givingFormConfig: IGivingFormConfig;
  methods: UseFormReturn<IGivingFormSchema>;
  eventHub: EventHub | null;
  isEditMode: boolean;
  sessionId: string;
  setDonationComplete: Dispatch<SetStateAction<boolean>>;
  setDonationObject: Dispatch<SetStateAction<SubmitDonationDataType | null>>;
  setDonationResponse: Dispatch<SetStateAction<Donation | null>>;
}) => {
  const { givingFormId } = useParams();
  const {
    campaignId,
    digitalWalletGatewayId,
    digitalWalletMerchantId,
    paymentGateway,
    givingFormVersion,
    givingFormVariant,
    organizationId,
    organizationName,
    paypalGatewayId,
    referenceCode,
    organizationFeatures,
    config,
    campaignDesignations
  } = useGivingFormData();
  const { useCheckbox, checkboxCaptchaToken } = useRecaptchaCheckbox();
  const [captcha, setCaptcha] = useState<RecaptchaElement>();
  const { setDisplayOverlay } = useDisabledOverlay();
  const [submitButtonError, setSubmitButtonError] = useState<string>('');
  const [, setInvisibleRecaptchaToken] = useState<string>('');
  const { setPaymentProviderToken, tokenPromise } = useCreditCardToken();
  const { initApplePay } = useApplePay(methods, eventHub);
  const utmCampaign = useQueryParam(IDonateQueryParam.UtmCampaign);
  const utmContent = useQueryParam(IDonateQueryParam.UtmContent);
  const utmMedium = useQueryParam(IDonateQueryParam.UtmMedium);
  const utmSource = useQueryParam(IDonateQueryParam.UtmSource);
  const utmTerm = useQueryParam(IDonateQueryParam.UtmTerm);
  const customerMeta = useQueryParam(IDonateQueryParam.CustomerMeta);
  const designationQueryParam = useQueryParam(IDonateQueryParam.Designation);
  const { registerDonation } = useDoubleTheDonation();

  // Position recaptcha modal right above donate button
  useChangeRecaptchaPosition();

  useEffect(() => {
    if (!resolvedEnvironment.easyPass && !useCheckbox) {
      // on mount, load invisible recaptcha if checkbox not triggered by recent declines
      setCaptcha(
        recaptcha.wrapElement(
          'captcha-challenge',
          resolvedEnvironment.recaptchaInvisibleKey,
          {
            size: 'invisible'
          }
        )
      );
    }
  }, []);

  const onSubmit = async (givingFormData: IGivingFormSchema) => {
    setSubmitButtonError('');
    if (givingFormData.paymentOption !== 'GOOGLEPAY') {
      setDisplayOverlay(true);
    }

    const customerMetaOverrides = JSON.parse(customerMeta || '{}');

    const { creditCard } = givingFormData;
    if (creditCard && creditCard.cvv) {
      creditCard.cvv = ''; // we're not doing anything with this field so an empty string should be ok?
    }

    const givingFormSubmission = {
      customerMetaOverrides,
      ...givingFormData,
      sessionId,
      utmCampaign,
      utmContent,
      utmMedium,
      utmSource,
      utmTerm,
      creditCard
    } as Partial<SubmitDonationDataType>;

    // When it's Apple Pay or Google Pay, this unselects the recurring options from a previous selected value and on the server side sets it to once.
    if (
      givingFormData.paymentOption === AllPaymentOptions.GOOGLEPAY ||
      givingFormData.paymentOption === AllPaymentOptions.APPLEPAY
    ) {
      givingFormSubmission.recurringOption = 'Once';
    }

    // This processes the actual donation to the backend and handles the overlay and transition to thank you page.
    // It is separated
    const submitDonation = async () => {
      if (!resolvedEnvironment.easyPass) {
        if (useCheckbox) {
          givingFormSubmission.captchaToken = checkboxCaptchaToken;
          givingFormSubmission.captchaType = 'v2';
        } else {
          const reCaptchaToken = await captcha
            ?.resolveToken()
            .then((tk: string) => {
              setInvisibleRecaptchaToken(tk);
              return tk;
            })
            .catch(() => {
              // A failed captcha response will return a 400 from the donation response
              // Since we're using the invisible version of the captcha it won't trigger the checkboxes on failure
              setInvisibleRecaptchaToken('');
              setDisplayOverlay(false);
              return '';
            });
          givingFormSubmission.captchaToken = reCaptchaToken;
          givingFormSubmission.captchaType = 'invisible';
        }
      }

      if (givingFormSubmission.billing?.email) {
        // submit data to Sift before processing donation, using the billing email as userId
        submitSiftTracking(givingFormSubmission.billing.email);
      }

      let shouldRedirect = false;

      try {
        setDonationObject(givingFormSubmission as SubmitDonationDataType);
        const donation = await submitDonationService(
          givingFormSubmission as SubmitDonationDataType
        );
        const donationData = {
          ...(givingFormSubmission as SubmitDonationDataType),
          ...donation
        };
        // After donation success, call doublethedonation register
        // TODO: temporarily only submit if donor selected a company
        if (
          organizationFeatures?.sendAllDonationsToDtd ||
          givingFormSubmission.corporateMatchingCompanyId
        ) {
          registerDonation(donationData);
        }
        setDonationResponse(donation);

        // emit a donation complete event for GTM
        const submitEvent = new CustomEvent('donationSubmit', {
          detail: {
            donorName: donation.fullName,
            donorEmail: givingFormSubmission.billing?.email,
            donationAmount: getDonationAmount(
              givingFormSubmission.designation,
              givingFormSubmission.giftAmount
            ),
            donationFrequency: givingFormSubmission.recurringOption,
            paymentType: givingFormSubmission.paymentOption,
            transactionId: donation.transactionId,
            transactionTotal: donation.transactionTotal
          }
        });
        window.dispatchEvent(submitEvent);

        window.parent.postMessage(
          {
            name: EditorEventTypes.DonationComplete,
            giftAmount: givingFormSubmission.giftAmount
          },
          '*'
        );

        if (config?.redirectURL) {
          const fullUrl = buildRedirectUrl(config?.redirectURL, donationData);
          window.parent.postMessage(
            {
              name: EditorEventTypes.PageRedirect,
              redirectUrl: fullUrl
            },
            '*'
          );
          shouldRedirect = true;
        }
      } catch (err) {
        setDisplayOverlay(false);
        const message =
          (err as AxiosError).response?.data?.message || 'Unable to process.'; // Fallback error message incase something unexpected threw a non-base Error
        setSubmitButtonError(message);
        return;
      }

      if (!shouldRedirect) {
        setDisplayOverlay(false);
        setDonationComplete(true);
        emitFormPageChange(ScrollPositions.top);
      }
    };

    // Custom fields need to be collected into the expected format for the backend:

    mapCustomFieldToDonation(givingFormSubmission);
    mapRecurringEndCountToEndDate(givingFormSubmission);

    givingFormSubmission.organizationId = organizationId;
    givingFormSubmission.campaignId = campaignId;
    givingFormSubmission.referenceCode = referenceCode;
    givingFormSubmission.gatewayId = paymentGateway?.id;

    givingFormSubmission.timezone =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    givingFormSubmission.givingFormId = givingFormId;
    givingFormSubmission.embedReferer =
      window.location !== window.parent.location
        ? document.referrer
        : window.location.href;
    // If only one designation is on the giving form,
    // selection will not be presented to user and
    // that designation is included on the donation object,
    // unless we have a query param to override it.
    // If more than one designation is available,
    // user is required to select one.
    const { designations } = givingFormConfig.blocks.find(
      (block) => block.blockType === BlockTypes.DesignationsBlock
    ) as IDesignationsBlock;
    if (designations.length <= 1) {
      let submissionDesignation;
      const queryParamObject = campaignDesignations.find(
        (d) => d.id === designationQueryParam
      );
      // if we have a designation query param and it is a valid
      // designation on the campaign, attach that to the donation object
      if (queryParamObject) {
        submissionDesignation = queryParamObject;
      } else {
        // otherwise, use the designation on the config
        // eslint-disable-next-line prefer-destructuring
        const firstDesId =
          (designations as Designation[])[0].id ?? designations[0];
        submissionDesignation = campaignDesignations.find(
          (d) => d.id === firstDesId
        );
      }
      givingFormSubmission.designation = [
        {
          ...submissionDesignation!,
          amount: givingFormData.giftAmount
        }
      ];
    } else {
      givingFormSubmission.designation = givingFormData.designation.map(
        ({ amount, id, title, code }) => ({ amount, id, title, code })
      );
    }

    if (givingFormVariant !== undefined) {
      // Only set this if it is defined since nulls as placeholder data in Keen are “free”
      givingFormSubmission.givingFormVariant =
        givingFormVariant === 'VariantA' ? 0 : 1;
    }
    givingFormSubmission.givingFormVersion = givingFormVersion;

    if (isEditMode) {
      setDisplayOverlay(false);
      return;
    }

    if (
      givingFormData.paymentOption === AllPaymentOptions.CREDIT ||
      givingFormData.paymentOption === AllPaymentOptions.DEBIT
    ) {
      if (window.Spreedly) {
        const fields: ITokenizationFields = {
          first_name: givingFormData.billing.firstName,
          last_name: givingFormData.billing.lastName,
          month: givingFormData.creditCard?.expirationDate.slice(0, 2) ?? '',
          year: `20${givingFormData.creditCard?.expirationDate.slice(2)}`
        };
        window.Spreedly.tokenizeCreditCard(fields);
      }
      const paymentToken = await tokenPromise;
      setPaymentProviderToken(paymentToken);
      givingFormSubmission.paymentToken = paymentToken;
    }

    if (givingFormData.paymentOption === 'ECHECK' && organizationId) {
      const paymentToken =
        paymentGateway?.backendName === 'card_connect'
          ? await tokenizeBankAccountCardConnect(organizationId, givingFormData)
          : await tokenizeBankAccountSpreedly(givingFormData);
      givingFormSubmission.paymentToken = paymentToken;
    }

    if (givingFormData.paymentOption === 'APPLEPAY') {
      initApplePay(givingFormSubmission, async () => {
        givingFormSubmission.gatewayId = digitalWalletGatewayId;
        submitDonation();
      });
    }

    if (givingFormData.paymentOption === 'PAYPAL') {
      if (!paypalGatewayId) {
        throw new Error('Paypal Gateway not configured.');
      }

      givingFormSubmission.gatewayId = paypalGatewayId;

      const paymentToken = await sdkClientPromise(
        organizationId as string
      ).then((client: iDonateClient) =>
        client.tokenizeSpreedlyPayPal({
          address: {
            address1: givingFormData.billing.address1,
            address2: givingFormData.billing.address2,
            city: givingFormData.billing.city,
            state:
              givingFormData.billing.state || givingFormData.billing.province,
            zip: givingFormData.billing.postalCode,
            country: givingFormData.billing.country
          },
          contact: {
            salutation: givingFormData.billing.prefix || '',
            firstName: givingFormData.billing.firstName,
            middleName: givingFormData.billing.middleName,
            lastName: givingFormData.billing.lastName,
            company: givingFormData.billing.companyName,
            email: givingFormData.billing.email,
            primaryPhone: givingFormData.billing.phoneNumber || ''
          }
        })
      );

      setPaymentProviderToken(paymentToken);
      givingFormSubmission.paymentToken = paymentToken;
    }

    if (givingFormData.paymentOption === 'GOOGLEPAY') {
      const payHandler = await getGooglePayHandler(
        organizationId as string,
        organizationName as string,
        digitalWalletMerchantId as string
      );

      const transactionInfo = {
        countryCode: givingFormSubmission.billing?.country,
        currencyCode: 'USD',
        totalPrice: (givingFormSubmission.giftAmount || 0).toFixed(2)
      };

      await payHandler.googlePay
        .onGooglePaymentButtonClicked(transactionInfo)
        .then((result) => {
          setDisplayOverlay(true);
          const billingInfo = result.paymentMethodData.info?.billingAddress;
          const billingName = util.splitName(
            billingInfo?.name || 'Cardholder Name'
          );
          Object.assign(givingFormSubmission, {
            billing: {
              firstName: billingName.firstName,
              lastName: billingName.lastName,
              email: result.email || '',
              address1: billingInfo?.address1 || '',
              address2: billingInfo?.address2,
              city: billingInfo?.locality,
              state: billingInfo?.administrativeArea,
              postalCode: billingInfo?.postalCode
            }
          } as Partial<SubmitDonationDataType>);

          return payHandler.googlePay.tokenizeWithCardConnect(
            result.paymentMethodData.tokenizationData.token,
            payHandler.sdk.config.cardConnectBaseUrl
          );
        })
        .then((cardConnectTokenResult) => {
          givingFormSubmission.gatewayId = digitalWalletGatewayId;
          givingFormSubmission.paymentToken = cardConnectTokenResult.token;

          submitDonation();
        });
    }

    if (
      givingFormData.paymentOption !== AllPaymentOptions.APPLEPAY &&
      givingFormData.paymentOption !== AllPaymentOptions.GOOGLEPAY
    ) {
      submitDonation();
    }
  };

  return { submitButtonError, onSubmit, setSubmitButtonError };
};
