import { useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import clsx from 'clsx';
import allCountries from 'country-region-data/data.json';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Button from 'components/lib/Button';
import Select, { SelectOption } from 'components/lib/Select';
import TextField from 'components/lib/TextField';
import { useExtraFormInfo } from 'hooks';
import { IBillingInfoBlock } from 'types';
import prefixes from 'utils/prefixes.json';
import './BillingInfo.scss';

type BillingInfoProps = Omit<IBillingInfoBlock, 'blockType'>;

const BillingInfo = ({
  enablePhone,
  enableCompanyName,
  enablePrefix,
  selectedPrefixes,
  enableMiddleName,
  enableInternationalDonations
}: BillingInfoProps) => {
  const { control, setValue, getValues, trigger, getFieldState } =
    useFormContext();
  const { setIsOrganizationRequired } = useExtraFormInfo();
  const [organizationBilling, setOrganizationBilling] =
    useState<boolean>(false);
  const country = useWatch({
    control,
    name: 'billing.country',
    defaultValue: 'US'
  });

  const getPrefixOptions = () => {
    if (!selectedPrefixes) {
      return prefixes.defaultPrefixes.map((prefix) => ({
        label: prefix,
        value: prefix
      })) as SelectOption[];
    }

    return selectedPrefixes.map((prefix) => ({
      label: prefix,
      value: prefix
    }));
  };

  const getCountryOptions = () =>
    allCountries.map((item) => ({
      label: item.countryName,
      value: item.countryShortCode
    }));

  const getStateOptions = () => {
    const selectedCountry = enableInternationalDonations
      ? getValues('billing.country')
      : 'US';
    const states = allCountries.find(
      (item) => item.countryShortCode === selectedCountry
    );
    const regions = states?.regions.map((region) => ({
      label: region.name,
      value: region.shortCode
    }));

    if (regions) return regions;
    return [];
  };
  const zone = country === 'US' ? 'State' : 'Province';

  const toggleOrganizationBilling = (isOrganizationBilling: boolean) => {
    setIsOrganizationRequired(isOrganizationBilling);
    setOrganizationBilling(isOrganizationBilling);
  };

  return (
    <Box className="GF-BillingInfo" id="GF-BillingInfo">
      <Grid container rowSpacing={0.75} columnSpacing={0.75}>
        {enableCompanyName && (
          <Grid item container xs={12}>
            <Grid className="GF-BillingInfo__toggle--container">
              <Stack
                className="GF-BillingInfo__toggle-button--container"
                direction="row"
                spacing={0.75}
              >
                <Button
                  className={clsx('toggleButton', {
                    selected: !organizationBilling
                  })}
                  onClick={() => toggleOrganizationBilling(false)}
                  name="billingIndividual"
                  fullWidth
                >
                  Individual
                </Button>
                <Button
                  className={clsx('toggleButton', {
                    selected: organizationBilling
                  })}
                  onClick={() => toggleOrganizationBilling(true)}
                  name="billingOrganization"
                  fullWidth
                >
                  Organization
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}

        {organizationBilling && (
          <Grid className="GF__GridInputWrapper" item xs={12}>
            <Controller
              name="billing.companyName"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  className="GF-BillingInfo__textfield GF-BillingInfo__textfield--company-name"
                  fullWidth
                  label="Organization Name *"
                  error={!!error}
                  helperText={error?.message ?? null}
                />
              )}
            />
          </Grid>
        )}

        <Grid item container xs={12}>
          <Grid className="GF-BillingInfo__prefix--container" item xs={12}>
            {enablePrefix && (
              <Controller
                name="billing.prefix"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    className="GF-BillingInfo__select GF-BillingInfo__select--prefix"
                    color="primary"
                    label="Prefix"
                    options={getPrefixOptions() as SelectOption[]}
                  />
                )}
              />
            )}
          </Grid>
        </Grid>

        <Grid container item xs={12} rowSpacing={0.75} columnSpacing={0.75}>
          <Grid
            className="GF__GridInputWrapper"
            item
            xs={12}
            sm={enableMiddleName ? 4 : 6}
          >
            <Controller
              name="billing.firstName"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  className="GF-BillingInfo__textfield GF-BillingInfo__textfield--first-name"
                  fullWidth
                  label="First Name *"
                  error={!!error}
                  helperText={error?.message ?? null}
                />
              )}
            />
          </Grid>
          {enableMiddleName && (
            <Grid className="GF__GridInputWrapper" item xs={12} sm={4}>
              <Controller
                name="billing.middleName"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    className="GF-BillingInfo__textfield GF-BillingInfo__textfield--middle-name"
                    fullWidth
                    label="Middle Name"
                  />
                )}
              />
            </Grid>
          )}
          <Grid
            className="GF__GridInputWrapper"
            item
            xs={12}
            sm={enableMiddleName ? 4 : 6}
          >
            <Controller
              name="billing.lastName"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  className="GF-BillingInfo__textfield GF-BillingInfo__textfield--last-name"
                  fullWidth
                  label="Last Name *"
                  error={!!error}
                  helperText={error?.message ?? null}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid
          className="GF__GridInputWrapper"
          item
          xs={12}
          sm={enablePhone ? 6 : 12}
        >
          <Controller
            name="billing.email"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                {...field}
                className="GF-BillingInfo__textfield GF-BillingInfo__textfield--email"
                type="email"
                fullWidth
                label={organizationBilling ? 'Organization Email *' : 'Email *'}
                error={!!error}
                helperText={error?.message ?? null}
              />
            )}
          />
        </Grid>
        {/* note kstickel 4/7/22: phone number requirement will
        eventually be a configurable in giving form editor,
        pending new designs for editor. Until requirement
        toggle is available, phone number will not be a
        required field */}
        {enablePhone && (
          <Grid className="GF__GridInputWrapper" item xs={12} sm={6}>
            <Controller
              name="billing.phoneNumber"
              control={control}
              defaultValue=""
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  className="GF-BillingInfo__textfield GF-BillingInfo__textfield--phone-number"
                  type="tel"
                  fullWidth
                  label={
                    organizationBilling
                      ? 'Organization Phone Number'
                      : 'Phone Number'
                  }
                  error={!!error}
                  helperText={error?.message ?? null}
                />
              )}
            />
          </Grid>
        )}

        {enableInternationalDonations && (
          <Grid
            className="GF__GridInputWrapper GF-BillingInfo__select--container"
            item
            xs={12}
          >
            <Controller
              name="billing.country"
              control={control}
              defaultValue={getValues('billing.country')}
              render={({ field: { ref, onChange, value, ...field } }) => (
                <Select
                  {...field}
                  key={value}
                  defaultValue={value}
                  className="GF-BillingInfo__select GF-BillingInfo__select--country"
                  onChange={(event) => {
                    onChange(event);
                    // Clear the state/province if the country has changed
                    if (event.target.value !== value) {
                      setValue('billing.state', '');
                      setValue('billing.province', '');

                      // re-validate the postal code if the field is touched and country has changed
                      if (getFieldState('billing.postalCode').isTouched) {
                        trigger('billing.postalCode');
                      }
                    }
                  }}
                  color="primary"
                  label="Country"
                  options={getCountryOptions() as SelectOption[]}
                />
              )}
            />
          </Grid>
        )}

        <Grid className="GF__GridInputWrapper" item xs={12}>
          <Controller
            name="billing.address1"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                {...field}
                className="GF-BillingInfo__textfield GF-BillingInfo__textfield--address-1"
                fullWidth
                label="Address 1 *"
                error={!!error}
                helperText={error?.message ?? null}
              />
            )}
          />
        </Grid>

        <Grid className="GF__GridInputWrapper" item xs={12}>
          <Controller
            name="billing.address2"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                className="GF-BillingInfo__textfield GF-BillingInfo__textfield--address-2"
                fullWidth
                label="Address 2"
              />
            )}
          />
        </Grid>

        <Grid className="GF__GridInputWrapper" item xs={12} sm={6}>
          <Controller
            name="billing.city"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                {...field}
                className="GF-BillingInfo__textfield GF-BillingInfo__textfield--city"
                fullWidth
                label="City *"
                error={!!error}
                helperText={error?.message ?? null}
              />
            )}
          />
        </Grid>

        <Grid
          className="GF__GridInputWrapper GF-BillingInfo__select--container"
          item
          xs={12}
          sm={3}
        >
          <Controller
            name={`billing.${zone.toLowerCase()}`}
            control={control}
            defaultValue={getValues(`billing.${zone.toLowerCase()}`)}
            render={({
              field: { ref, onChange, value, ...field },
              fieldState: { error }
            }) => (
              <Select
                {...field}
                key={value}
                defaultValue={value}
                label={`${zone} *`}
                className={`GF-BillingInfo__select GF-BillingInfo__select--${zone.toLowerCase()}`}
                onChange={(event) => {
                  onChange(event);
                }}
                options={getStateOptions() as SelectOption[]}
                fullWidth
                error={!!error}
                helperText={error?.message ?? null}
              />
            )}
          />
        </Grid>

        <Grid className="GF__GridInputWrapper" item xs={12} sm={3}>
          <Controller
            name="billing.postalCode"
            control={control}
            defaultValue=""
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                {...field}
                className="GF-BillingInfo__textfield GF-BillingInfo__textfield--postal-code"
                fullWidth
                label="Postal Code *"
                error={!!error}
                helperText={error?.message ?? null}
              />
            )}
          />
        </Grid>
      </Grid>
      <div className="confirm-email-input-wrapper">
        <Controller
          name="billing.confirmEmail"
          control={control}
          defaultValue=""
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              autoComplete="off"
              className="GF-BillingInfo__textfield--confirm-email"
              fullWidth
              inputProps={{ tabIndex: -1 }}
              label="Confirm Email *"
            />
          )}
        />
      </div>
    </Box>
  );
};

export default BillingInfo;
