import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import Button from 'components/lib/Button';
import Icon, { ICONS } from 'components/lib/Icon';
import Text from 'components/lib/Text';
import { TributeBlockType } from 'types';
import './TributeBlock.scss';
import { TributeModal } from './TributeModal';

interface ExtendedTributeBlockProps extends TributeBlockType {
  selectedPrefixes?: string[];
}

export const TributeBlock = (tributeProps: ExtendedTributeBlockProps) => {
  const { control } = useFormContext();
  const { tributeSectionTitle, isEnabled: isTributeEnabled } = tributeProps;
  const [isTributeSelected, setIsTributeSelected] = useState<boolean>(false);
  const [isTributeModalOpen, setIsTributeModalOpen] = useState<boolean>(false);

  const tributePurpose = useWatch({
    control,
    name: 'tribute.tributePurpose'
  });

  const tributeFirstName = useWatch({
    control,
    name: 'tribute.tributeFirstName'
  });
  const tributeLastName = useWatch({
    control,
    name: 'tribute.tributeLastName'
  });
  const tributeEcardMessage = useWatch({
    control,
    name: 'tribute.ecardCustomMessage'
  });
  const tributeEcardImage = useWatch({
    control,
    name: 'tribute.ecardImage'
  });
  const tributeFullName = `${tributeFirstName}${' '}${tributeLastName}`;

  const renderTribute = () => {
    if (isTributeSelected) {
      return (
        <div className="tribute-preview">
          <div className="tribute-preview-hover">
            <Button
              name="tribute"
              onClick={() => setIsTributeModalOpen(true)}
              startIcon={<Icon icon={ICONS.PENCIL} />}
              variant="secondary"
            >
              Edit
            </Button>
          </div>
          <div className="tribute-preview-title">
            <Icon viewBox="0 0 31 32" icon={ICONS.DODECAGON_CHECK} />
            <Text variant="h5"> Tribute Selected</Text>
          </div>
          <div className="tribute-content">
            {tributeEcardImage && (
              <img
                className="tribute-ecard-image"
                src={tributeEcardImage}
                alt={tributeEcardImage}
              />
            )}
            <div className="tribute-detail">
              <Text className="tribute-honoree" variant="h4">
                In {tributePurpose} of: {tributeFullName}
              </Text>
              {tributeEcardMessage && (
                <Text className="tribute-message" variant="caption">
                  {tributeEcardMessage}
                </Text>
              )}
            </div>
          </div>
        </div>
      );
    }

    if (isTributeEnabled) {
      return (
        <div className="tribute-prompt">
          {tributeSectionTitle && (
            <Text className="tribute-prompt-section-title" variant="h5">
              {tributeSectionTitle}
            </Text>
          )}
          <Button
            name="tribute"
            onClick={() => setIsTributeModalOpen(true)}
            variant="secondary"
          >
            Select Tribute
          </Button>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="tribute">
      <div id="tribute-modal-target" />
      {renderTribute()}
      {isTributeModalOpen && (
        <TributeModal
          open
          onClose={() => setIsTributeModalOpen(false)}
          isTributeSelected={isTributeSelected}
          setIsTributeSelected={setIsTributeSelected}
          {...tributeProps}
        />
      )}
    </div>
  );
};
